define("@woody-lite/addon-webcomponents/components/wrapper-lit", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@woody-lite/addon-webcomponents/utils/woody-page-loader", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _woodyPageLoader, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-insert this.add}}>
  	<WrapperLitCore
  		@network={{this.network}}
  		@contextualHelpId={{@contextualHelpId}}
  		@srcAudio={{@srcAudio}}
  		@resetValuesPodcast={{@resetValuesPodcast}}
  		@blueData={{@blueData}}
  	/>
  	{{yield}}
  </div>
  */
  {
    "id": "ATdcSSLx",
    "block": "[[[11,0],[4,[38,1],[[30,0,[\"add\"]]],null],[12],[1,\"\\n\\t\"],[8,[39,2],null,[[\"@network\",\"@contextualHelpId\",\"@srcAudio\",\"@resetValuesPodcast\",\"@blueData\"],[[30,0,[\"network\"]],[30,1],[30,2],[30,3],[30,4]]],null],[1,\"\\n\\t\"],[18,5,null],[1,\"\\n\"],[13]],[\"@contextualHelpId\",\"@srcAudio\",\"@resetValuesPodcast\",\"@blueData\",\"&default\"],false,[\"div\",\"did-insert\",\"wrapper-lit-core\",\"yield\"]]",
    "moduleName": "@woody-lite/addon-webcomponents/components/wrapper-lit.hbs",
    "isStrictMode": false
  });

  /**
  	Tenemos que generar las siguientes estructuras para los transition-container a primer nivel de la feature
  	-	Dentro de la feature:
  
  			<feature>
  				<elemento>
  					<slot name="slotN" slot="slotInElement"></slot>
  				</elemento>
  			</feature>
  
  	-	Dentro del woody-page:
  
  			<woody-page>
  				<feature>
  					<slot name="slotN" slot="slotN"></slot>
  				</feature>
  			</woody-page>
  
  	-	En el hbs:
  
  			<woody-page>
  				<core slot="slotN"></core>
  			</woody-page>
  
  	Con esto logramos mantener la parte de core, dentro del lightDOM para mantener los estilos de la app
  	Para la pantalla principal en la que tenemos el mixin de configuración tenemos habilitado el slot customHeaderContainer
  	<feature>
  		<shadow>
  			<slot name="customHeaderContainer">
  		</shadow>
  	</feature>
  
  	En el woody-page generamos la estructura:
  	<woody-page>
  		<shadow>
  			<slot name="appComponents" slot="customHeaderContainer">
  		</shadow>
  	</woody-page>
  
  	Y en el hbs:
  
  	<woody-page>
  		<core slot="appComponents">
  	</woody-page>
   */

  const slots = new Map([['bbva-transition-container', 'transitionCustomHeaderContainer']]);
  let WrapperLitComponent = _exports.default = (_class = class WrapperLitComponent extends _component2.default {
    get network() {
      if (this.args.network !== undefined) {
        return this.args.network;
      }
      return true;
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "device", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      _defineProperty(this, "coreSelector", '[data-id=core]');
      this.parents = [];
    }
    _addSlotToTransition(element, index) {
      const div = document.createElement('div');
      const slotName = slots.get(element.getAttribute('data-tag-name'));
      div.setAttribute('slot', slotName);
      const slot = document.createElement('slot');
      slot.setAttribute('name', "customSlot".concat(index));
      div.append(slot);
      element.append(div);
      return "customSlot".concat(index);
    }
    _addSlotToFeatureInWoodyPage(slotName) {
      const slotInWoodyPage = document.createElement('slot');
      slotInWoodyPage.setAttribute('slot', slotName);
      slotInWoodyPage.setAttribute('name', slotName);
      this.woodyPage._woodyFeature.append(slotInWoodyPage);
    }

    /**
     * Cuando se activa un elemento nuevo (apertura de un transition-container) vamos a coger el core del componente que lo tenga
     * para dárselo al nuevo
     */
    _bubblesCoreToParent(element) {
      const core = this.core;
      // Comprobamos si existe ya en pila, y no necesitamos crear toda la estructura de slots interna
      const containerIndex = this.parents.findIndex(parent => parent.element === element);
      this.parents.forEach(item => item.hasCore = false);
      let slotName = '';
      if (containerIndex >= 0) {
        // el contenedor ya existe y no debemos crear toda la estrucutra de slots asociada
        slotName = this.parents[containerIndex].slot;
        this.parents[containerIndex].hasCore = true;
      } else {
        const index = this.parents.length + 1;
        slotName = this._addSlotToTransition(element, index);
        this.parents.push({
          element,
          slot: slotName,
          hasCore: true
        });
        if (this.woodyPage._woodyFeature) {
          this._addSlotToFeatureInWoodyPage(slotName);
        }
      }
      core.setAttribute('slot', slotName);
    }
    /**
     * Cuando el elemento activo deja de estarlo, pasamos el core al elemento anterior en pila
     */
    _setNewActiveCoreNode(element) {
      var _this$parents$index;
      const index = this.parents.findIndex(parent => parent.element === element);
      if ((_this$parents$index = this.parents[index]) !== null && _this$parents$index !== void 0 && _this$parents$index.hasCore && this.parents[index - 1]) {
        this.core.setAttribute('slot', this.parents[index - 1].slot);
        this.parents[index - 1].hasCore = true;
      }
    }
    _removeAndGetCoreNode(element, selector) {
      const core = element.querySelector(selector);
      return core.parentNode.removeChild(core);
    }

    /**
     * It creates a layer inside the `customHeaderContainer` feature slot.
     * Inside this slot, it adds another slot from woody-page to insert ember components in its lightDOM to be able to apply app styles.
     */
    _addFeatureSlot() {
      const woodyPageHasHeader = Boolean(this.woodyPage.shadowRoot.querySelector('[data-tag-name=bbva-header-main]'));
      const slot = document.createElement('slot');
      const pipe = document.createElement('div');
      slot.setAttribute('name', woodyPageHasHeader ? 'appComponentsInHeader' : 'appComponents');
      pipe.appendChild(slot);
      woodyPageHasHeader ? this._setWoodyPageSlot(pipe) : this._setWoodyFeatureSlot(pipe);
    }
    async _setWoodyPageSlot(pipe) {
      pipe.setAttribute('id', 'appComponentsInHeader');
      await this.woodyPage.updateComplete;
      const mainElement = this.woodyPage.shadowRoot.querySelector('main');
      const referenceNode = mainElement.querySelector('section');
      mainElement === null || mainElement === void 0 || mainElement.insertBefore(pipe, referenceNode);
      this.woodyPage.requestUpdate();
    }
    async _setWoodyFeatureSlot(pipe) {
      var _this$woodyPage$_wood, _this$woodyPage$_wood2, _this$woodyPage$_wood3;
      pipe.setAttribute('slot', 'customHeaderContainer');
      await ((_this$woodyPage$_wood = this.woodyPage._woodyFeature) === null || _this$woodyPage$_wood === void 0 ? void 0 : _this$woodyPage$_wood.updateComplete);
      (_this$woodyPage$_wood2 = this.woodyPage._woodyFeature) === null || _this$woodyPage$_wood2 === void 0 || _this$woodyPage$_wood2.appendChild(pipe);
      (_this$woodyPage$_wood3 = this.woodyPage._woodyFeature) === null || _this$woodyPage$_wood3 === void 0 || _this$woodyPage$_wood3.requestUpdate();
    }

    /**
     * Método para el movimiento inicial del core al woody-page, y situarlo en la feature contenida
     * o en la cabecera del woody-page
     */
    _moveCoreNode(element) {
      this.core = this._removeAndGetCoreNode(element, this.coreSelector);
      this._addFeatureSlot();
      const woodyPageHasHeader = Boolean(this.woodyPage.shadowRoot.querySelector('[data-tag-name=bbva-header-main]'));
      const slot = woodyPageHasHeader ? 'appComponentsInHeader' : 'appComponents';

      // añadimos el core al woody-page, en el slot correspondiente
      this.woodyPage.append(this.core);
      this.core.setAttribute('slot', slot);
      this.parents.push({
        element: this.woodyPage,
        slot,
        hasCore: true
      });
    }
    async add(element) {
      element.addEventListener('transition-container-open', e => {
        const path = e.path || e.composedPath && e.composedPath() || [];
        const newParent = path[0];
        if (newParent) {
          this._bubblesCoreToParent(newParent);
        }
      });
      element.addEventListener('transition-container-close', e => {
        const path = e.path || e.composedPath && e.composedPath() || [];
        const transition = path[0];
        if (transition) {
          this._setNewActiveCoreNode(transition);
        }
      });
      this.woodyPage = (0, _woodyPageLoader.getWoodyPage)(element);
      if (this.woodyPage) {
        await this.woodyPage.updateComplete;
        this._moveCoreNode(element);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "device", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WrapperLitComponent);
});