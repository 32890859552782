define("@woody-lite/addon-webcomponents/adapters/intl-legacy", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IntlAdapterLegacy {
    constructor(intlService) {
      this.intl = intlService;
    }
    t(key, _fallback, interpolation) {
      const params = [key];
      if ((0, _utils.typeOf)(interpolation) === 'object') {
        params.push(interpolation);
      }
      if (this.intl.exists(key)) {
        return this.intl.t(...params).toString();
      }
      return key;
    }
  }
  _exports.default = IntlAdapterLegacy;
});