define("@woody-lite/addon-webcomponents/decorators/lit-route", ["exports", "@ember/object", "@ember/runloop", "@ember/service", "@ember/application", "@woody-lite/addon-webcomponents/constants", "@woody-lite/addon-webcomponents/decorators/events-binding", "@woody-lite/addon-webcomponents/decorators/ce-storage", "@woody-lite/addon-webcomponents/adapters/intl-legacy", "@woody-lite/addon-webcomponents/adapters/intl", "@woody-lite/addon-webcomponents/decorators/wc-navigation", "@woody-lite/core-session/decorators/session"], function (_exports, _object, _runloop, _service, _application, _constants, _eventsBinding, _ceStorage, _intlLegacy, _intl, _wcNavigation, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = LitRoute;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function LitRoute(options) {
    return function (Target) {
      var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
      const {
        intlKeysMap,
        module
      } = options;
      const Klass = (_dec = (0, _session.default)('client'), _dec2 = (0, _eventsBinding.default)('open-contextual-help'), (0, _wcNavigation.default)(_class = (0, _ceStorage.default)(_class = (_class2 = class LitRouteDecorator extends Target {
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "alerter", _descriptor, this);
          _initializerDefineProperty(this, "assetLoader", _descriptor2, this);
          _initializerDefineProperty(this, "currentSession", _descriptor3, this);
          _initializerDefineProperty(this, "intl", _descriptor4, this);
          _initializerDefineProperty(this, "slinky", _descriptor5, this);
          _initializerDefineProperty(this, "veil", _descriptor6, this);
          // Shared services to share with lit
          _initializerDefineProperty(this, "provider", _descriptor7, this);
          _initializerDefineProperty(this, "router", _descriptor8, this);
          _defineProperty(this, "events", []);
          const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
          this.basePath = config.rootURL;
        }
        async getConfig() {
          if (this.currentSession) {
            return this.provider.pull('configuration', 'getPrivateConfiguration');
          }
          return this.provider.pull('configuration', 'getPublicConfiguration');
        }
        transitionToExternalFlow(e) {
          return this.transitionToExternal(...this._digestTransitionArguments(e));
        }
        async addEvent(evt) {
          const flows = {
            'open-contextual-help': 'openContextualhelpFlow',
            'woody-wc-transitionToExternal': 'transitionToExternalFlow'
          };
          const currentFlow = flows[evt.type];
          if (currentFlow) {
            this.events.push({
              evt,
              status: 'waiting',
              currentFlow
            });
            await this.start();
          }
        }
        async start() {
          if (!this.events.some(item => item.status === 'processing')) {
            const currentEvent = this.events.find(item => item.status === 'waiting');
            if (currentEvent) {
              currentEvent.status = 'processing';
              await this[currentEvent.currentFlow](currentEvent.evt);
              this.events = this.events.filter(item => item !== currentEvent);
              await this.start();
            }
          }
          return;
        }
        setEnvironment() {
          this.waitForLit = new Promise(resolve => {
            this._waitForLitResolve = resolve;
          });
        }
        _showLoadError() {
          (0, _runloop.later)(() => {
            this.alerter.add({
              text: this.intl.t('error.criticalError'),
              visualType: 'warning'
            });
          });
        }

        // eslint-disable-next-line max-statements
        async beforeModel() {
          this.setEnvironment();
          this.veil.add();
          try {
            await this.loadWebComponentsBundles();
            this.setSharedConfig();
            await this.setTooglesConfig();
            this.setLanguageService();
            await this.loadRouteModule();
            this.veil.remove();
            return super.beforeModel(...arguments);
          } catch (e) {
            this._showLoadError();
            throw e;
          }
        }
        setSharedConfig() {
          if (!this.getStorageItem('appConfig')) {
            const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
            const appConfig = {
              APP: {
                hosts: {
                  BACKEND: config.network.hosts.enax
                },
                ENV: config.environment
              }
            };
            this.setStorageItem('appConfig', appConfig);
            this.setStorageItem('getCurrentRouteName', () => {
              return this.router.currentRouteName;
            });
            _constants.SHARED_SERVICES.forEach(sharedService => {
              this.setStorageItem(sharedService, (0, _object.get)(this, sharedService));
            });
          }
        }
        async setTooglesConfig() {
          const configuration = await this.getConfig();
          const help = false;
          this.help = Boolean(help);
          this.setStorageItem('configurationNLPisActive', (0, _object.get)(configuration, 'NLP.isActive'));
          this.setStorageItem('configurationBbvaBotIsActive', (0, _object.get)(configuration, 'bbvaBot.isActive'));
          this.setStorageItem('showContextualHelp', (0, _object.get)(configuration, 'showContextualHelp.isActive'));
          this.setStorageItem('helpRouteExists', help);
        }
        loadRouteModule() {
          const mountPoint = (0, _application.getOwner)(this).mountPoint;
          const [, packageName] = mountPoint.split('/');
          const [workspace, moduleName] = packageName.split(/-(.*)/);
          const a = "".concat(workspace === 'core' ? workspace : "".concat(workspace, "s"), "/").concat(moduleName);
          const moduleId = "".concat(a, "/").concat(module);
          return this.assetLoader.loadAsset({
            uri: moduleId,
            type: 'systemjs'
          });
        }
        setLanguageService() {
          const ceStorage = customElements.get('bbva-ce-storage');
          const intlKeysMapMap = intlKeysMap || {};
          if (!ceStorage.get('intl')) {
            const intlAdapter = new _intl.default(this.intl);
            intlAdapter.addKeys(intlKeysMapMap);
            ceStorage.set('i18n', new _intlLegacy.default(this.intl));
            ceStorage.set('intl', intlAdapter);
          } else {
            const intlAdapter = ceStorage.get('intl');
            intlAdapter.addKeys(intlKeysMapMap);
          }
        }
        _openContextualhelp(e) {
          this.addEvent(e);
        }
        async openContextualhelpFlow() {
          if (this.help) {
            const configuration = await this.getConfig();
            if ((0, _object.get)(configuration, 'NLP.isActive') && (0, _object.get)(configuration, 'bbvaBot.isActive')) {
              this.events = [];
            }
            const refreshContextualHelp = true;
            this.clickHelpButton(refreshContextualHelp);
          }
          return true;
        }
      }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "alerter", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "assetLoader", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "currentSession", [_dec], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "slinky", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "veil", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "provider", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _applyDecoratedDescriptor(_class2.prototype, "_openContextualhelp", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "_openContextualhelp"), _class2.prototype), _class2)) || _class) || _class);
      return Klass;
    };
  }
});