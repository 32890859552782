define("@woody-lite/addon-webcomponents/decorators/wc-navigation", ["exports", "@woody-lite/addon-webcomponents/decorators/events-binding", "@ember/service"], function (_exports, _eventsBinding, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = wcNavigation;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function wcNavigation(Target) {
    var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;
    const Klass = (_dec = (0, _eventsBinding.default)('woody-wc-transition'), _dec2 = (0, _eventsBinding.default)('woody-wc-replace'), _dec3 = (0, _eventsBinding.default)('woody-wc-transitionToExternal'), _dec4 = (0, _eventsBinding.default)('woody-wc-replaceWithExternal'), _dec5 = (0, _eventsBinding.default)('woody-wc-redirect'), _dec6 = (0, _eventsBinding.default)('woody-wc-back'), _dec7 = (0, _eventsBinding.default)('open-menu'), _class = class wcNavigationDecorator extends Target {
      constructor() {
        super(...arguments);
        _initializerDefineProperty(this, "state", _descriptor, this);
        _initializerDefineProperty(this, "router", _descriptor2, this);
      }
      _digestTransitionArguments(e) {
        var _e$detail;
        let args = [e.detail.route];
        if (e.detail.params) {
          args = args.concat(e.detail.params);
        }
        args.push({
          queryParams: ((_e$detail = e.detail) === null || _e$detail === void 0 ? void 0 : _e$detail.queryParams) || {}
        });
        return args;
      }
      _transitionTo(e) {
        return this.router.transition(...this._digestTransitionArguments(e));
      }
      _replaceWith(e) {
        return this.router.replace(...this._digestTransitionArguments(e));
      }
      _transitionToExternal(e) {
        this.addEvent(e);
      }
      _replaceWithExternal(e) {
        return this.replaceWithExternal(...this._digestTransitionArguments(e));
      }
      _redirect(e) {
        return this.router.transition(...this._digestTransitionArguments(e));
      }
      _backStep() {
        this.send('back');
      }
      _openMenu() {
        this.send('menu');
      }
    }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class.prototype, "_transitionTo", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_transitionTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_replaceWith", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_replaceWith"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_transitionToExternal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_transitionToExternal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_replaceWithExternal", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_replaceWithExternal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_redirect", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_redirect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_backStep", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "_backStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_openMenu", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "_openMenu"), _class.prototype), _class);
    Object.defineProperty(Klass, 'name', {
      value: Target.name
    });
    return Klass;
  }
});