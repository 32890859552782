define("@woody-lite/addon-webcomponents/adapters/intl", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class IntlAdapter {
    constructor(intlService) {
      _defineProperty(this, "litKeys", {});
      this.intl = intlService;
    }
    addKeys(intlKeysMapMap) {
      this.litKeys = {
        ...this.litKeys,
        ...intlKeysMapMap
      };
    }
    t(key) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const intl = this.intl;
      const {
        messageKey,
        messageParams
      } = this._extractParams(key, params);
      if (this.litKeys[messageKey] && intl.exists(this.litKeys[messageKey])) {
        return intl.t(this.litKeys[messageKey], messageParams).toString();
      }
      if (intl.exists(messageKey)) {
        return intl.t(messageKey, messageParams).toString();
      }
      if (messageKey === ('bbva-list-card-not-available' || '_Not_Available')) {
        return '';
      }
      return messageKey;
    }
    _extractParams(key, params) {
      let messageKey = key;
      let messageParams = params || {};
      let paramsA;
      let paramsB;
      if (Array.isArray(key) && key.length > 0) {
        [messageKey, paramsA, paramsB] = key;

        // Interpolation
        if ((0, _utils.typeOf)(paramsA) === 'object') {
          messageParams = Object.assign(messageParams, paramsA);
        }

        // Interpolation
        if ((0, _utils.typeOf)(paramsB) === 'object') {
          messageParams = Object.assign(messageParams, paramsB);
        }
      }
      return {
        messageKey,
        messageParams
      };
    }
  }
  _exports.default = IntlAdapter;
});