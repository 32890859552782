define("@woody-lite/addon-webcomponents/utils/wc-bundles-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WEBCOMPONENTS_POLYFILL_URI = 'assets/vendor/webcomponents/webcomponents-loader.js';
  const WEBCOMPONENTS_STORAGE_URI = 'assets/vendor/webcomponents/bbva-ce-storage.min.js';
  const SYSTEM_JS = 'assets/vendor/webcomponents/s.min.js';
  const CUSTOMELEMENT_DEFINE = 'assets/vendor/webcomponents/customelements-define.js';
  class WebComponentsBundlesLoader {
    constructor(assetLoader, assetMap, basePath) {
      this.assetLoader = assetLoader;
      this.assetMap = assetMap;
      this.basePath = basePath;
    }
    async loadWebcomponentsPolyfills() {
      const loadScript = uri => this.assetLoader.loadAsset({
        type: 'js',
        uri
      });
      await loadScript("".concat(this.basePath).concat(CUSTOMELEMENT_DEFINE));
      if (this._testSystemJsNeeded()) {
        await loadScript("".concat(this.basePath).concat(SYSTEM_JS));
      }
      if (this._testPolyfillsNeeded()) {
        const promises = [loadScript("".concat(this.basePath).concat(WEBCOMPONENTS_POLYFILL_URI)), loadScript("".concat(this.basePath).concat(WEBCOMPONENTS_STORAGE_URI))];
        return Promise.all(promises);
      }
      return Promise.resolve();
    }
    _testSystemJsNeeded() {
      var _window;
      return ((_window = window) === null || _window === void 0 ? void 0 : _window.System) === undefined;
    }
    _testPolyfillsNeeded() {
      var _window2;
      return ((_window2 = window) === null || _window2 === void 0 || (_window2 = _window2.customElements) === null || _window2 === void 0 ? void 0 : _window2.get('bbva-ce-storage')) === undefined;
    }
  }
  _exports.default = WebComponentsBundlesLoader;
});