define("@woody-lite/addon-webcomponents/decorators/events-binding", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = EventsBinding;
  /**
   * Decorator to bind events to methods in the lifecycle of the route.
   * Binds the decorated method to an event or a list of events on the
   * 'activate' lifecycle hook of the Route and unbinds it on the 'deacivate' hook.
   *
   * Use into an Ember Route:
   *
   * ```js
   * import Route from '@ember/routing/route';
   * import eventsBinding from '@woody/webcomponents/decorators/events-binding';
   *
   * export default class MyRoute extends Route {
   *     // Bind an event to a method (1..1)
   *     @eventsBinding('event-to-bind')
   *     _methodToExecuteWhenOneEvent() {
   *         ....
   *     }
   * 		 // Bind a method to a list of events (1..N)
   *     @eventsBinding('event-to-bind-1', 'event-to-bind-2', ...)
   *     _methodToExecuteWhenManyEvents() {
   *         ....
   *     }
   * }
   * ```
   */
  function EventsBinding() {
    for (var _len = arguments.length, events = new Array(_len), _key = 0; _key < _len; _key++) {
      events[_key] = arguments[_key];
    }
    return function actualeventsBindingDecorator(target, methodName, descriptor) {
      if (!(target instanceof _route.default)) {
        throw new Error('[eventsBinding] Decorator is not being applied on a (valid) route.');
      }
      events.forEach(eventName => {
        if (!(typeof eventName === 'string')) {
          throw new Error('[eventsBinding] Event name is not valid');
        }
      });
      if (!target.__eventsBindings) {
        target.__eventsBindings = [];
        const originalActivate = target.activate;
        const originalDeactivate = target.deactivate;
        target.activate = function () {
          for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            args[_key2] = arguments[_key2];
          }
          originalActivate.apply(this, args);
          this.__eventsBindings.forEach(binding => {
            binding.applied = binding.descriptor.value.bind(this);
            document.addEventListener(binding.eventName, binding.applied);
          });
        };
        target.deactivate = function () {
          for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
            args[_key3] = arguments[_key3];
          }
          originalDeactivate.apply(this, args);
          this.__eventsBindings.forEach(binding => {
            document.removeEventListener(binding.eventName, binding.applied);
          });
        };
      }
      events.forEach(eventName => {
        target.__eventsBindings.push({
          eventName,
          descriptor
        });
      });
      return descriptor;
    };
  }
});